@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.cart-goods-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0.2rem;
  border-top: 1px solid #f1f1f1;
  background-color: #fff;
}
.cart-goods-item:first-child {
  border-top: 0;
}
.cart-goods-item > .checkbox {
  -webkit-align-self: center;
          align-self: center;
}
.cart-goods-item > .img-wrap {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 1.6rem;
  height: 1.6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.2rem;
  position: relative;
}
.cart-goods-item > .img-wrap > .sold-out {
  position: absolute;
  top: 0;
  left: 0;
  background-size: 70%;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.cart-goods-item > .img-wrap > .sold-out:before {
  content: "\8865\8D27\4E2D";
  font-size: 90%;
  padding: 0.1rem 0.2rem;
  letter-spacing: 0.2em;
  border-radius: 100vw;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
.cart-goods-item > .inner {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.cart-goods-item > .inner > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.cart-goods-item > .inner > .header > .name {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  line-height: 1.2;
  font-size: 0.3rem;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cart-goods-item > .inner > .header > .del {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  white-space: nowrap;
  margin-left: 0.05rem;
}
.cart-goods-item > .inner > .sku > span {
  display: inline-block;
  font-size: 0.22rem;
  line-height: 1.5;
  padding: 0 0.1rem;
  border: 1px solid #e52779;
  border: 1px solid var(--color-main, #e52779);
  color: #e52779;
  color: var(--color-main, #e52779);
  border-radius: 0.165rem;
}
.cart-goods-item > .inner > .price-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.second-header[data-v-4c2105c6] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2rem;
  background-color: #fff;
}
.second-footer[data-v-4c2105c6] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  padding: 0.1rem 0.2rem;
}
.second-footer > .btn[data-v-4c2105c6] {
  -webkit-align-self: stretch;
          align-self: stretch;
}

/*# sourceMappingURL=cart.7a9b23ab.css.map*/