@import "@/assets/style/_index.scss";

































































































































































































































































.second-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $padding;
  background-color: #fff;
}

.second-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  padding: $padding-small $padding;
  > .btn {
    align-self: stretch;
  }
}
